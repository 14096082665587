<template>
    <div class="alcFour">
        <div class="afBox1" v-if="getAdvData(39).children">
            <a :href="getAdvData(39).children[0].url" target="_blank" rel="noopener noreferrer">
                <img v-if="getAdvData(39).children[0].image" :src="getAdvData(39).children[0].image" class="afBg" />
                <img
                    :src="getAdvData(39).children[0].children.find(e => e.id == 319).image"
                    class="af1 wow animate__animated animate__fadeInUp"
                />
                <img
                    :src="getAdvData(39).children[0].children.find(e => e.id == 350).image"
                    class="af2 wow animate__animated animate__fadeInUp"
                />
            </a>
        </div>
        <div class="afBox2">
            <img src="http://iv.vu818.com/img/alet3.jpg" alt="" class="af2Bg" />
            <div class="af2Top">
                <p class="af2p1">为什么要做 <span>小程序</span></p>
                <p class="af2p2">
                    小程序是一种不需要下载安装即可使用的应用。小程序由于其不用下载、打开方便、传播便利，功能强大等特点，一经问世，便深受大家喜爱，发展非常迅速。目前小程序全国保有量已超过500万，成功超越WEB网站、APP，成为用户开发的首选。
                </p>
                <div class="af2List">
                    <ul>
                        <li class="af2Li">
                            <div class="a2l1">
                                <img src="http://iv.vu818.com/img/alet4.png" alt="" class="a2lIcon" />
                            </div>
                            <p class="a2l2">流量红利</p>
                        </li>
                        <li class="af2Li">
                            <div class="a2l1">
                                <img src="http://iv.vu818.com/img/alet5.png" alt="" class="a2lIcon" />
                            </div>
                            <p class="a2l2">无需安装</p>
                        </li>
                        <li class="af2Li">
                            <div class="a2l1">
                                <img src="http://iv.vu818.com/img/alet6.png" alt="" class="a2lIcon" />
                            </div>
                            <p class="a2l2">功能强大</p>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="af2Bot">
                <div class="a2bBox">
                    <img src="http://iv.vu818.com/img/alet7.jpg" alt="" class="a2bBg" />
                    <div class="a2bY" v-for="item in 7" :key="item">
                        <i class="ayI"></i>
                    </div>
                    <div class="a2bList">
                        <ul>
                            <li class="a2bLi">
                                <p class="a2l1">生产制造类/工作流类</p>
                                <p class="a2l2">适用范围：工厂制造业、营销类等</p>
                            </li>
                            <li class="a2bLi">
                                <p class="a2l1">智能硬件类</p>
                                <p class="a2l2">适用范围：结合硬件使用的相关产品</p>
                            </li>
                            <li class="a2bLi">
                                <p class="a2l1">社区类/同城类</p>
                                <p class="a2l2">适用范围：论坛等、社交类、互动类、区域性服务类等</p>
                            </li>
                            <li class="a2bLi">
                                <p class="a2l1">视频类/直播类/会议类</p>
                                <p class="a2l2">适用范围：教育培训类、内训类等</p>
                            </li>
                            <li class="a2bLi">
                                <p class="a2l1">服务类/预订类/上门服务类</p>
                                <p class="a2l2">适用范围：服务业、行政单位、事业单位等</p>
                            </li>
                            <li class="a2bLi">
                                <p class="a2l1">抽奖/红包/分销裂变类</p>
                                <p class="a2l2">适用范围：希望获取新用户的各个行业</p>
                            </li>
                            <li class="a2bLi">
                                <p class="a2l1">商城类/支付类小程序</p>
                                <p class="a2l2">适用范围：各个行业</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "AlcFour",
    inject: ["getAdvData"],
    mounted() {
        this.$AOS.init({
            offset: 200,
            duration: 600,
            easing: "ease-in-sine",
            delay: 100,
        });
        new this.$wow.WOW().init({
            boxClass: "wow",
            animateClass: "animate__animated",
            offset: 0,
            mobile: true,
            live: true,
        });
    },
};
</script>

<style lang="less" scoped>
.alcFour {
    width: 100%;
    .afBox1 {
        // max-width: 1920px;
        height: 635px;
        margin: 0 auto;
        position: relative;
        .afBg {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        .af1 {
            position: absolute;
            left: 100px;
            top: 230px;
        }
        .af2 {
            position: absolute;
            right: 0px;
            top: 30px;
        }
    }
    .afBox2 {
        width: 100%;
        height: 1185px;
        overflow: hidden;
        position: relative;
        .af2Bg {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            object-fit: cover;
            z-index: -1;
        }
        .af2Top {
            width: 100%;
            margin-top: 50px;
            text-align: center;
            .af2p1 {
                width: 100%;
                height: 64px;
                line-height: 64px;
                font-size: 48px;
                font-weight: bold;
                span {
                    color: #1a6fd6;
                }
            }
            .af2p2 {
                width: 1106px;
                height: 60px;
                line-height: 30px;
                margin: 0 auto;
                margin-top: 10px;
            }
            .af2List {
                width: 50%;
                height: 175px;
                margin: 0 auto;
                margin-top: 40px;
                ul {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: space-around;
                    .af2Li {
                        width: 80px;
                        height: 116px;
                        .a2l1 {
                            width: 80px;
                            height: 80px;
                            border-radius: 50%;
                            border: 1px solid rgb(26, 111, 214);
                            background-color: #fafafa;
                            .a2lIcon {
                                margin: 0 auto;
                                margin-top: 17px;
                            }
                        }

                        .a2l2 {
                        }
                    }
                }
            }
        }
        .af2Bot {
            width: 100%;
            height: 783px;
            .a2bBox {
                width: 890px;
                height: 633px;
                margin: 0 auto;
                margin-top: 150px;
                position: relative;
                .a2bBg {
                    width: 100%;
                    height: 100%;
                }
                .a2bY {
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    position: relative;
                    .ayI {
                        width: 20%;
                        height: 20%;
                        border-radius: 50%;
                        background-color: rgb(58, 100, 203);
                        position: absolute;
                        left: 40%;
                        top: 40%;
                        overflow: hidden;
                    }
                }
                .a2bY::before {
                    width: 100%;
                    height: 100%;
                    left: 0;
                    top: 0;
                    background-color: rgba(58, 100, 203, 0.3);
                    animation: spot1 1s infinite linear;
                    content: "";
                    position: absolute;
                    transform-origin: 50% 50%;
                    overflow: hidden;
                    border-radius: 50%;
                }
                .a2bY::after {
                    width: 60%;
                    height: 60%;
                    left: 20%;
                    top: 20%;
                    background-color: rgba(58, 100, 203, 0.5);
                    animation: spot1 1s infinite linear;
                    content: "";
                    position: absolute;
                    transform-origin: 50% 50%;
                    overflow: hidden;
                    border-radius: 50%;
                }
                @keyframes spot1 {
                    0% {
                        transform: scale(0.5);
                    }
                    50% {
                        transform: scale(1);
                    }
                    100% {
                        transform: scale(1.2);
                    }
                }
                .a2bY:nth-child(2) {
                    position: absolute;
                    left: 3px;
                    bottom: 160px;
                }
                .a2bY:nth-child(3) {
                    position: absolute;
                    left: 46px;
                    bottom: 340px;
                }
                .a2bY:nth-child(4) {
                    position: absolute;
                    left: 170px;
                    top: 120px;
                }
                .a2bY:nth-child(5) {
                    position: absolute;
                    left: 435px;
                    top: 28px;
                }
                .a2bY:nth-child(6) {
                    position: absolute;
                    right: 160px;
                    top: 120px;
                }
                .a2bY:nth-child(7) {
                    position: absolute;
                    right: 37px;
                    bottom: 340px;
                }
                .a2bY:nth-child(8) {
                    position: absolute;
                    right: -6px;
                    bottom: 160px;
                }
                .a2bList {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    ul {
                        width: 100%;
                        height: 100%;
                        .a2bLi {
                            width: 240px;
                            height: 100px;

                            .a2l1 {
                                width: 100%;
                                height: 36px;
                                line-height: 36px;
                                font-size: 20px;
                                font-weight: bold;
                            }
                            .a2l2 {
                                width: 100%;
                                height: 64px;
                                line-height: 32px;
                                font-size: 18px;
                            }
                        }
                        .a2bLi:nth-child(1) {
                            position: absolute;
                            left: -230px;
                            bottom: 90px;
                        }
                        .a2bLi:nth-child(2) {
                            position: absolute;
                            left: -230px;
                            bottom: 320px;
                        }
                        .a2bLi:nth-child(3) {
                            position: absolute;
                            left: -100px;
                            top: 40px;
                        }
                        .a2bLi:nth-child(4) {
                            position: absolute;
                            left: 320px;
                            top: -90px;
                        }
                        .a2bLi:nth-child(5) {
                            position: absolute;
                            right: -140px;
                            top: 70px;
                        }
                        .a2bLi:nth-child(6) {
                            position: absolute;
                            right: -220px;
                            bottom: 280px;
                        }
                        .a2bLi:nth-child(7) {
                            position: absolute;
                            right: -255px;
                            bottom: 110px;
                        }
                    }
                }
            }
        }
    }
}
</style>
