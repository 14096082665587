<template>
    <div class="appFifteen">
        <div class="affBox">
            <!-- 标题 -->
            <div class="affTitle">
                <p class="affBig">小程序开发</p>
                <p class="affSmall">威有科技各平台小程序开发应用，打造流畅的用户体验，满足您对功能的个性化需求。</p>
                <p class="affSmall">威有科技注重整个平台的交互效果、用户体验、兼容性、个性化定制、安全性、数据分析处理。</p>
            </div>
            <!-- 转圈圈 -->
            <div class="affCircles">
                <!-- 中间图 -->
                <div class="affcImg">
                    <img src="http://iv.vu818.com/img/quan.png" alt="" class="affImg" />
                </div>
                <!-- 五个小圆圈 -->
                <div class="affFive">
                    <dt>
                        <dl class="affDl">
                            <div class="dlImg">
                                <img src="http://iv.vu818.com/img/vu818.png" alt="" />
                            </div>
                            <p class="dlText">数据分析</p>
                        </dl>
                        <dl class="affDl">
                            <div class="dlImg">
                                <img src="http://iv.vu818.com/img/vu818.png" alt="" />
                            </div>
                            <p class="dlText">个性定制</p>
                        </dl>
                        <dl class="affDl">
                            <div class="dlImg">
                                <img src="http://iv.vu818.com/img/vu818.png" alt="" />
                            </div>
                            <p class="dlText">用户体验</p>
                        </dl>
                        <dl class="affDl">
                            <div class="dlImg">
                                <img src="http://iv.vu818.com/img/vu818.png" alt="" />
                            </div>
                            <p class="dlText">交互效果</p>
                        </dl>
                        <dl class="affDl">
                            <div class="dlImg">
                                <img src="http://iv.vu818.com/img/vu818.png" alt="" />
                            </div>
                            <p class="dlText">兼容性</p>
                        </dl>
                        <dl class="affDl">
                            <div class="dlImg">
                                <img src="http://iv.vu818.com/img/vu818.png" alt="" />
                            </div>
                            <p class="dlText">安全性</p>
                        </dl>
                    </dt>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "AlcThree",
};
</script>

<style lang="less" scoped>
.appFifteen {
    width: 100%;
    height: 1500px;
    background: url("https://iv.vu818.com/img/H_img_5.jpg") center no-repeat;
    background-position-y: 922px;
    .affBox {
        width: 1280px;
        height: 100%;
        margin: 0 auto;
        overflow: hidden;
        // 标题
        .affTitle {
            width: 60%;
            height: 100px;
            margin: 0 auto;
            margin-top: 100px;
            .affBig {
                width: 100%;
                height: 50px;
                line-height: 50px;
                font-size: 36px;
                text-align: center;
            }
            .affSmall {
                width: 100%;
                height: 25px;
                line-height: 25px;
                text-align: center;
            }
        }
        // 转圈圈
        .affCircles {
            width: 100%;
            height: 900px;
            margin-top: 200px;
            position: relative;
            // 中间图
            .affcImg {
                width: 800px;
                height: 800px;
                margin: 0 auto;
                text-align: center;
                background-color: #fff;
                border-radius: 50%;
                box-shadow: 1px 1px 5px 3px rgb(243, 242, 242);
                .affImg {
                    padding-top: 80px;
                    box-sizing: border-box;
                }
            }
            //   五个小圆
            .affFive {
                width: 70%;
                height: 90%;
                position: absolute;
                top: 0;
                left: 190px;
                // 动画
                transform-origin: center center;
                animation-name: circle;
                animation-duration: 30s;
                animation-iteration-count: infinite;
                animation-timing-function: linear;
                dt {
                    width: 100%;
                    height: 100%;
                    .affDl {
                        width: 140px;
                        height: 140px;
                        border-radius: 50%;
                        background-color: white;
                        box-shadow: 1px 1px 5px 3px gray;
                        text-align: center;
                        animation: an 30s linear infinite;
                        @keyframes an {
                            100% {
                                transform: rotate(-360deg);
                            }
                        }
                        .dlImg {
                            width: 50px;
                            height: 50px;
                            overflow: hidden;
                            margin: 30px auto 0;
                            // padding-top: 30px;
                            position: relative;
                            & > img {
                                position: absolute;
                            }
                        }
                        .dlText {
                            width: 140px;
                            height: 50px;
                            line-height: 50px;
                            font-size: 20px;
                            font-weight: 900;
                            color: gray;
                        }
                    }
                    .affDl:nth-child(1) {
                        // width: 170px;
                        // height: 170px;
                        position: absolute;
                        top: -80px;
                        left: 380px;

                        .dlText {
                            // width: 170px;
                            height: 50px;
                            line-height: 50px;
                            font-size: 20px;
                            font-weight: 900;
                            color: gray;
                        }
                        .dlImg {
                            & > img {
                                top: -916px;
                                left: -1040px;
                            }
                        }
                    }
                    .affDl:nth-child(2) {
                        position: absolute;
                        top: 125px;
                        left: 5px;
                        .dlImg {
                            & > img {
                                top: -841px;
                                left: -1480px;
                            }
                        }
                    }
                    .affDl:nth-child(3) {
                        position: absolute;
                        top: 530px;
                        left: 5px;
                        .dlImg {
                            & > img {
                                top: -841px;
                                left: -1424px;
                            }
                        }
                    }
                    .affDl:nth-child(4) {
                        // width: 170px;
                        // height: 170px;
                        position: absolute;
                        bottom: -60px;
                        right: 350px;

                        .dlText {
                            // width: 170px;
                            height: 50px;
                            line-height: 50px;
                            font-size: 20px;
                            font-weight: 900;
                            color: gray;
                        }
                        .dlImg {
                            & > img {
                                top: -912px;
                                left: -1165px;
                            }
                        }
                    }
                    .affDl:nth-child(5) {
                        position: absolute;
                        bottom: 110px;
                        right: 25px;
                        .dlImg {
                            & > img {
                                top: -914px;
                                left: -1104px;
                            }
                        }
                    }
                    .affDl:nth-child(6) {
                        position: absolute;
                        top: 125px;
                        right: 17px;
                        .dlImg {
                            & > img {
                                top: -841px;
                                left: -1373px;
                            }
                        }
                    }
                }
            }
        }
    }
    // 转圈动画
    @keyframes circle {
        0% {
            transform: rotate(0);
        }
        25% {
            transform: rotate(90deg);
        }
        50% {
            transform: rotate(180deg);
        }
        75% {
            transform: rotate(270deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
}
</style>
